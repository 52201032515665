import React, { Component, Suspense, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import clsx from "clsx";
import Moment from "moment";
import $ from "jquery";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { DownloadExcel } from "../ExcelHelper";
import ReactSelect from "react-select";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import Columns from '../../../_metronic/helpers/Columns';
import { getModuleDataDisplaySettingByKey, getUsers, updateModuleDataDisplaySettingByKey } from "../../models/_user";
import { Option } from '../../../_metronic/helpers/MySelect';
import { getLocationsForFilter } from "../../models/_location";
import { getWorkOrderById, getWorkOrders } from "../../models/_worOrder";
import { ImageModal } from "./ImageModal";
import { SignatureImage } from "./SignatureImage";
import { getMachinesNameAndSerials } from "../../models/_machine";
class WorkOrderList extends Component<any, any> {
    readonly props: any;

    constructor(props: any, state: any) {
        super(props);
        let searchParams = new URLSearchParams(window.location.search);
        const currentPage = searchParams.get('page');
        const fromDate = searchParams.get('from_date');
        const toDate = searchParams.get('to_date');
        const date = searchParams.get('date');
        const firstName = searchParams.get('first_name');
        const lastName = searchParams.get('last_name');
        const deploymentIdString = searchParams.get('deployment_manager_id');
        const deploymentIdsArray = deploymentIdString ? deploymentIdString.split(',').map((x) => x) : "";
        const locationIdString = searchParams.get('location_id');
        const locationIdsArray = locationIdString ? locationIdString.split(',').map((x) => x) : "";

        this.state = {
            addLoading: false,
            filterLoading: false,
            resetFrom: createRef(),
            resetTo: createRef(),
            resetDate: createRef(),
            downloadLoading: false,
            filterFromDate: fromDate || "",
            filterToDate: toDate || "",
            filterDeployment: deploymentIdsArray || "",
            filterLocation: locationIdsArray || "",
            filterFirstName: firstName || "",
            filterLastName: lastName || "",
            filterDate: date || "",
            loading: false,
            deploymentManagerSelected: null,
            locationSelected: null,
            managerDB: [],
            locationDB: [],
            machineDB: [],
            finalUrl: '',
            page: currentPage || 0,
            perPage: 50,
            moduleKeyName: 'customer_list',
            selectedColumns: [],
            workOrderImageModal: false,
            imageContent: {},
            currentWorkOrderId: "",
            signatureImageModal: false,

            currentlyOpenRecord: {},
            columnData: [
                { id: 0, label: 'Sr.No.', key: 'index' },
                { id: 1, label: 'Deployment Manager Id', key: 'deploymentManagerId' },
                { id: 2, label: 'Location Id', key: 'locationId' },
                { id: 3, label: 'Comment', key: 'comment' },
                { id: 4, label: 'Machine 1', key: 'machineId1' },
                { id: 5, label: 'Is Second Machine Installed', key: 'isSecondMachineInstalled' },
                { id: 6, label: 'Machine 2', key: 'machineId2' },
                { id: 7, label: 'First Name', key: 'firstName' },
                { id: 8, label: 'Last Name', key: 'lastName' },
                { id: 9, label: 'Date', key: 'date' },
                { id: 10, label: 'Signature', key: 'signature' },
                { id: 11, label: 'Freezer Installed', key: 'freezerInstalled' },
                { id: 12, label: 'Footer Installed', key: 'footerInstalled' },
                { id: 13, label: 'Sanitizer Installed', key: 'sanitizerInstalled' },
                { id: 14, label: 'Header Installed', key: 'headerInstalled' },
                { id: 15, label: 'Booster Bar Installed', key: 'boosterBarInstalled' },
                { id: 16, label: 'Drainage Installed', key: 'drainageInstalled' },
                { id: 17, label: 'Internet Connected', key: 'internetConnected' },
                { id: 18, label: 'Flavor Tags Installed', key: 'flavorTagsInstalled' },
                { id: 19, label: 'Service Sticker Installed', key: 'serviceStickerInstalled' },
                { id: 20, label: 'Operating Pressures', key: 'operatingPressures' },
                { id: 21, label: 'One Two Three Stickers Installed', key: 'oneTwoThreeStickersInstalled' },
                { id: 22, label: 'Palm Tree', key: 'palmTree' },
                { id: 23, label: 'Filled Freezer', key: 'filledFreezer' },
                { id: 24, label: 'Blended Four Smoodies', key: 'blendedFourSmoodies' },
                { id: 25, label: 'Sanitizer', key: 'sanitizer' },
                { id: 26, label: 'Get Status', key: 'getStatus' },
                { id: 27, label: 'Making Smoodies', key: 'makingSmoodies' },
                { id: 28, label: 'Benefits Of Smoodi (Trained Staff)', key: 'benefitsOfSmoodi(Trained Staff)' },
                { id: 29, label: 'Accessing Service Menu (Trained Staff)', key: 'accessingServiceMenu(Trained Staff)' },
                { id: 30, label: 'Cleaning Wipers (Trained Staff)', key: 'cleaningWipers(Trained Staff)' },
                { id: 31, label: 'Checking Sanitizer (Trained Staff)', key: 'checkingSanitizer(Trained Staff)' },
                { id: 32, label: 'Booster Pump', key: 'boosterPump' },
                { id: 33, label: 'Benefits Of Smoodi (Optional Materials)', key: 'benefitsOfSmoodi(Optional Materials)' },
                { id: 34, label: 'Accessing Service Menu (Optional Materials)', key: 'accessingServiceMenu(Optional Materials)' },
                { id: 35, label: 'Cleaning Wipers (Optional Materials)', key: 'cleaningWipers(Optional Materials)' },
                { id: 36, label: 'Checking Sanitizer (Optional Materials)', key: 'checkingSanitizer(Optional Materials)' },
                { id: 37, label: 'Action (Required Task)', key: 'action(Required Task)' },
                { id: 38, label: 'Priority', key: 'priority' },
                { id: 39, label: 'Due Date', key: 'dueDate' },
                { id: 40, label: 'Action', key: 'action' },

            ]
        };
    }


    async componentWillMount() {
    }

    async componentDidUpdate() {
        await this.handleWorkOrderFilterSet()
    }

    async componentDidMount() {
        var _ = this;
        await this.getFilterRecords();
        this.renderDataTable([]);
        $("body").on("click", ".image", async function () {
            const record: any = $(this).attr("data-recordId");
            const workOrderData = await getWorkOrderById(record);
            _.setState({
                workOrderImageModal: true,
                currentWorkOrderId: record,
                imageContent: workOrderData,
            })
        });
        $("body").on("click", ".signatureImage", async function () {
            const workOder: any = $(this).attr("data-recordId");
            _.setState({
                signatureImageModal: true,

                currentlyOpenRecord: workOder,
            });
        });
    }

    getFilter = () => {
        let filter: any = {};
        if (this.state.filterDeployment.length > 0) {
            filter["deployment_manager_id"] = { in: this.state.filterDeployment };
        }
        if (this.state.filterLocation.length > 0) {
            filter["location_id"] = { in: this.state.filterLocation };
        }

        if (this.state.filterFirstName) {
            filter["first_name"] = { like: "%" + this.state.filterFirstName + "%" };
        }
        if (this.state.filterLastName) {
            filter["last_name"] = { like: "%" + this.state.filterLastName + "%" };
        }
        if (this.state.filterDate) {
            filter["date"] = { eq: this.state.filterDate };
        }

        if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
            filter["created_at"] = {};
        }
        if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
            filter["created_at"]["gt"] = new Date(
                this.state.filterFromDate
            ).toISOString();
        } else if (
            this.state.filterFromDate === "" &&
            this.state.filterToDate !== ""
        ) {
            filter["created_at"]["lt"] = new Date(
                this.state.filterToDate
            ).toISOString();
        } else if (
            this.state.filterFromDate !== "" &&
            this.state.filterToDate !== ""
        ) {
            filter["created_at"]["between"] = [
                new Date(this.state.filterFromDate).toISOString(),
                new Date(this.state.filterToDate).toISOString(),
            ];
        }
        return filter
    }
    getURL = (baseURL: string) => {
        const isApplyDeployMentFilter = this.state.filterDeployment;
        const isApplyFromDateFilter = this.state.filterFromDate;
        const isApplyToDateFilter = this.state.filterToDate;
        const isApplyFirstNameFilter = this.state.filterFirstName;
        const isApplyLastNameFilter = this.state.filterLastName;
        const isApplyLocationFilter = this.state.filterLocation;
        const isApplyDateFilter = this.state.filterDate;

        if (isApplyFromDateFilter) {
            let date = Moment(isApplyFromDateFilter).format('YYYY-MM-DD HH:mm')
            let encodedDate = encodeURIComponent(date);
            baseURL += `&from_date=${encodedDate}`
        }
        if (isApplyToDateFilter) {
            let date = Moment(isApplyToDateFilter).format('YYYY-MM-DD HH:mm')
            let encodedDate = encodeURIComponent(date);
            baseURL += `&to_date=${encodedDate}`
        }
        if (isApplyDateFilter) {
            let date = Moment(isApplyDateFilter).format('YYYY-MM-DD HH:mm')
            let encodedDate = encodeURIComponent(date);
            baseURL += `&date=${encodedDate}`
        }
        if (isApplyDeployMentFilter && isApplyDeployMentFilter.length > 0) {
            const distributerIds = isApplyDeployMentFilter.join(',');
            baseURL += `&deployment_manager_id=${distributerIds}`
        }
        if (isApplyLocationFilter) {
            const accountTypeIds = isApplyLocationFilter.join(',');
            baseURL += `&location_id=${accountTypeIds}`
        }
        if (isApplyFirstNameFilter) {
            let decodedCustomerName = decodeURIComponent(isApplyFirstNameFilter);
            let encodedCustomerName = encodeURIComponent(decodedCustomerName);
            baseURL += `&first_name=${encodedCustomerName}`;
        }
        if (isApplyLastNameFilter) {
            let decodedCustomerName = decodeURIComponent(isApplyLastNameFilter);
            let encodedCustomerName = encodeURIComponent(decodedCustomerName);
            baseURL += `&last_name=${encodedCustomerName}`;
        }

        return baseURL;
    }


    // rendering datatable jquery
    renderDataTable = async (rows: any) => {
        var _this = this;
        // const getPermissions: any = localStorage.getItem("permissions");
        // const permissions = getPermissions || [];
        const filter = this.getFilter();
        await this.getColumnData();
        const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        let authToken = "";
        if (lsValue) {
            const user = JSON.parse(lsValue);
            authToken = user.access_token;
        }

        $(document).ready(function () {
            $("#workOrderTable").DataTable().destroy();
            // eslint-disable-next-line
            const table = $("#workOrderTable").DataTable({
                pagingType: "full_numbers",
                serverSide: true,
                pageLength: _this.state.perPage,
                paging: true,
                searching: false,
                "ajax": {
                    "url": process.env.REACT_APP_API_URL,
                    "type": "POST",
                    "contentType": "application/json",
                    "beforeSend": function (xhr) {
                        xhr.setRequestHeader('Authorization',
                            "Bearer " + authToken);
                    },
                    "data": function (d: any) {
                        var gql = `query  getWorkOrders(  
            $where: WorkOrderResolver_GetWorkOrders_FilterInputType 
            $paginate:PaginatorArgs
            ) {
                getWorkOrders(where: $where ,paginate: $paginate) {
                    data {
                        id
                        deploymentManagerId
                        locationId
                        closeUpMedia
                        storeSetUpMedia
                        drainSetUpMedia
                        serialNumberMedia
                        comment
                        machineSerialNumber1
                        machineName1
                        machineSerialNumber2
                        machineName2
                        machineId1
                        isSecondMachineInstalled
                        machineId2
                        firstName
                        lastName
                        date
                        signature
                        createdAt
                        installationSteps{
                            workOrderId
                        freezerInstalled
                        footerInstalled
                        sanitizerInstalled
                        headerInstalled
                        boosterBarInstalled
                        drainageInstalled
                        internetConnected
                        flavorTagsInstalled
                        serviceStickerInstalled
                        operatingPressures
                        oneTwoThreeStickersInstalled
                        palmTree
                        filledFreezer
                        createdAt
                        }
                        testPerformance{
                        workOrderId
                        blendedFourSmoodies
                        sanitizer
                        getStatus
                        createdAt
                        }
                        staffTraining{
                            workOrderId
                        makingSmoodies
                        benefitsOfSmoodi
                        accessingServiceMenu
                        cleaningWipers
                        checkingSanitizer
                        createdAt
                        }
                        materialInstallation{
                        workOrderId
                        boosterPump
                        benefitsOfSmoodi
                        accessingServiceMenu
                        cleaningWipers
                        checkingSanitizer
                        createdAt
                        }
                        requiredTask{
                            workOrderId
                        action
                        priority
                        dueDate
                        createdAt
                        }
                        }
                        totalPages
                        total
                        perPage
                        page
                  }
          }`;
                        const currentPage = (d?.start) ? (d.start / d.length) + 1 : 1;
                        const length = d?.length > 0 ? d.length : 50;
                        var query = {
                            "operationName": null,
                            "query": gql,
                            "variables": {
                                "where": filter,
                                paginate: {

                                    "page": currentPage,
                                    "per_page": length

                                }
                            }
                        };

                        return JSON.stringify(query);
                    },
                    "dataSrc": function (json) {

                        json.recordsTotal = json.data.getWorkOrders.total;
                        json.recordsFiltered = json.data.getWorkOrders.total;

                        const rows: any[] = [];
                        const records = json.data.getWorkOrders.data;
                        const getDeploymentManagerName = (id: string) => {
                            const manager = _this.state.managerDB.find(
                                (record: any) => {
                                    return record.id === id
                                }
                            );
                            return manager ? manager.firstName : '';
                        };
                        const getLocationName = (id: string) => {
                            const location = _this.state.locationDB.find(
                                (record: any) => {
                                    return record.id === id
                                }
                            );
                            return location ? location.name : '';
                        };

                        let counter = 0;
                        for (const record of records) {
                            counter++;
                            let createdAt = "";
                            if (record.createdAt) {
                                createdAt = Moment.utc(record.createdAt)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss");
                            }
                            const tempRecord: any = {
                                id: counter,
                                recordId: record.id,
                                managerName: getDeploymentManagerName(record.deploymentManagerId),
                                locationName: getLocationName(record.locationId),
                                closeUpMedia: record.closeUpMedia,
                                storeSetUpMedia: record.storeSetUpMedia,
                                drainSetUpMedia: record.drainSetUpMedia,
                                serialNumberMedia: record.serialNumberMedia,
                                comment: record.comment,
                                machineSerialNumber1: record.machineSerialNumber1,
                                machineName1: record.machineName1,
                                machineSerialNumber2: record.machineSerialNumber2,
                                machineName2: record.machineName2,
                                machineId1: record.machineId1,
                                isSecondMachineInstalled: record.isSecondMachineInstalled === true ? "Yes" : "No",
                                machineId2: record.machineId2,
                                firstName: record.firstName,
                                lastName: record.lastName,
                                date: record.date ? Moment.utc(record.date)
                                    .local()
                                    .format("YYYY-MM-DD") : '',
                                signature: record.signature,
                                installationSteps: record.installationSteps,
                                testPerformance: record.testPerformance,
                                staffTraining: record.staffTraining,
                                materialInstallation: record.materialInstallation,
                                requiredTask: record.requiredTask,
                                createdAt,
                                record: record,
                            };
                            const subRecords = record.subTasks ?? "";
                            for (const subRecord of subRecords) {
                                subRecord["record"] = { ...subRecord };
                            }
                            rows.push(tempRecord);
                        }
                        if (rows && rows.length !== 0) {
                            _this.setState({
                                customersList: rows
                            })
                        }
                        return rows;
                    }
                },
                "initComplete": function (settings, json) {
                    table.page(Number(_this.state.page)).draw(false);

                },
                // data: rows,
                columns: [
                    {
                        name: "index",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
                        render: function (data, type, row) {
                            return row.id;

                        },
                    },
                    {
                        name: "deploymentManagerId",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('deploymentManagerId'),
                        render: function (data, type, row) {
                            return row.managerName;
                        },
                    },
                    {
                        name: "locationId",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('locationId'),
                        render: function (data, type, row) {
                            return row.locationName;
                        },
                    },
                    {
                        name: "comment",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('comment'),
                        render: function (data, type, row) {
                            return row.comment;
                        },
                    },

                    {
                        name: "machineId1",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('machineId1'),
                        render: function (data, type, row) {
                            const matchedMachine = _this.state.machineDB.find((machine: any) => machine.id === row.machineId1);
                            const machineName = matchedMachine ? matchedMachine.name : '';
                            const machineSerialNumber = matchedMachine ? matchedMachine.serialNumber : '';
                            return row.machineId1 ? `${machineSerialNumber} (${machineName})` : ''
                        },
                    },

                    {
                        name: "isSecondMachineInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('isSecondMachineInstalled'),
                        render: function (data, type, row) {
                            return row.isSecondMachineInstalled;
                        },
                    },
                    {
                        name: "machineId2",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('machineId2'),
                        render: function (data, type, row) {
                            const matchedMachine = _this.state.machineDB.find((machine: any) => machine.id === row.machineId2);
                            const machineName = matchedMachine ? matchedMachine.name : '';
                            const machineSerialNumber = matchedMachine ? matchedMachine.serialNumber : '';
                            return row.machineId2 ? `${machineSerialNumber} (${machineName})` : ''
                        },
                    },
                    {
                        name: "firstName",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('firstName'),
                        render: function (data, type, row) {
                            return row.firstName;
                        },
                    },
                    {
                        name: "lastName",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('lastName'),
                        render: function (data, type, row) {
                            return row.lastName;
                        },
                    },
                    {
                        name: "date",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('date'),
                        render: function (data, type, row) {
                            return row.date;
                        },
                    },
                    {
                        name: "signature",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('signature'),
                        render: function (data, type, row) {
                            const mediaUrls = row.signature;
                            return `
                              <div style="display: flex; flex-wrap: wrap; gap: 5px; max-width: 100%; overflow: auto;">
                                        <img 
                                          data-recordId="${mediaUrls}"
                                          class=" signatureImage"
                                          src="${mediaUrls}"
                                          alt="Signature"
                                          style="max-width: 90px; height: auto; margin-right: 5px; margin-top: 5px; cursor: pointer; object-fit: contain;"
                                        />
                              </div>
                            `;
                        }
                    },
                    {
                        name: "freezerInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('freezerInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.freezerInstalled
                        },
                    },

                    {
                        name: "footerInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('footerInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.footerInstalled;
                        },
                    },
                    {
                        name: "sanitizerInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('sanitizerInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.sanitizerInstalled;
                        },
                    },
                    {
                        name: "headerInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('headerInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.headerInstalled;
                        },
                    },
                    {
                        name: "boosterBarInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('boosterBarInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.boosterBarInstalled;
                        },
                    },
                    {
                        name: "drainageInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('drainageInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.drainageInstalled;
                        },
                    },
                    {
                        name: "internetConnected",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('internetConnected'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.internetConnected;
                        },
                    },
                    {
                        name: "flavorTagsInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('flavorTagsInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.flavorTagsInstalled;
                        },
                    },

                    {
                        name: "serviceStickerInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('serviceStickerInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.serviceStickerInstalled;
                        },
                    },
                    {
                        name: "operatingPressures",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('operatingPressures'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.operatingPressures;
                        },
                    },
                    {
                        name: "oneTwoThreeStickersInstalled",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('oneTwoThreeStickersInstalled'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.oneTwoThreeStickersInstalled;
                        },
                    },

                    {
                        name: "palmTree",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('palmTree'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.palmTree;
                        },
                    },
                    {
                        name: "filledFreezer",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('filledFreezer'),
                        render: function (data, type, row) {
                            return row.installationSteps && row.installationSteps.filledFreezer;
                        },
                    },
                    {
                        name: "blendedFourSmoodies",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('blendedFourSmoodies'),
                        render: function (data, type, row) {
                            return row.testPerformance && row.testPerformance.blendedFourSmoodies;
                        },
                    },

                    {
                        name: "sanitizer",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('sanitizer'),
                        render: function (data, type, row) {
                            return row.testPerformance && row.testPerformance.sanitizer;
                        },
                    },
                    {
                        name: "getStatus",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('getStatus'),
                        render: function (data, type, row) {
                            return row.testPerformance && row.testPerformance.getStatus;
                        },
                    },
                    {
                        name: "makingSmoodies",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('makingSmoodies'),
                        render: function (data, type, row) {
                            return row.testPerformance && row.staffTraining.makingSmoodies;
                        },
                    },
                    {
                        name: "benefitsOfSmoodi(Trained Staff)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('benefitsOfSmoodi(Trained Staff)'),
                        render: function (data, type, row) {
                            return row.staffTraining && row.staffTraining.benefitsOfSmoodi;
                        },
                    },
                    {
                        name: "accessingServiceMenu(Trained Staff)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('accessingServiceMenu(Trained Staff)'),
                        render: function (data, type, row) {
                            return row.staffTraining && row.staffTraining.accessingServiceMenu;
                        },
                    },
                    {
                        name: "cleaningWipers(Trained Staff)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('cleaningWipers(Trained Staff)'),
                        render: function (data, type, row) {
                            return row.staffTraining && row.staffTraining.cleaningWipers;
                        },
                    },
                    {
                        name: "checkingSanitizer(Trained Staff)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('checkingSanitizer(Trained Staff)'),
                        render: function (data, type, row) {
                            return row.staffTraining && row.staffTraining.checkingSanitizer;
                        },
                    },
                    {
                        name: "boosterPump",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('boosterPump'),
                        render: function (data, type, row) {
                            return row.materialInstallation && row.materialInstallation.boosterPump;
                        },
                    },
                    {
                        name: "benefitsOfSmoodi(Optional Materials)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('benefitsOfSmoodi(Optional Materials)'),
                        render: function (data, type, row) {
                            return row.materialInstallation && row.materialInstallation.benefitsOfSmoodi;
                        },
                    },
                    {
                        name: "accessingServiceMenu(Optional Materials)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('accessingServiceMenu(Optional Materials)'),
                        render: function (data, type, row) {
                            return row.materialInstallation && row.materialInstallation.accessingServiceMenu;
                        },
                    },
                    {
                        name: "cleaningWipers(Optional Materials)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('cleaningWipers(Optional Materials)'),
                        render: function (data, type, row) {
                            return row.materialInstallation && row.materialInstallation.cleaningWipers;
                        },
                    },
                    {
                        name: "checkingSanitizer(Optional Materials)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('checkingSanitizer(Optional Materials)'),
                        render: function (data, type, row) {
                            return row.materialInstallation && row.materialInstallation.checkingSanitizer;
                        },
                    },
                    {
                        name: "action(Required Task)",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('action(Required Task)'),
                        render: function (data, type, row) {
                            const test = row.requiredTask.map((a: any) => {
                                return a.action
                            })
                            return test;
                        },
                    },
                    {
                        name: "priority",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('priority'),
                        render: function (data, type, row) {
                            const test = row.requiredTask.map((a: any) => {
                                return a.priority
                            })
                            return test;
                        },
                    },
                    {
                        name: "dueDate",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('dueDate'),
                        render: function (data, type, row) {
                            const test = row.requiredTask.map((a: any) => {
                                return a.dueDate ? Moment.utc(a.dueDate)
                                    .local()
                                    .format("YYYY-MM-DD") : ''
                            })
                            return test;
                        },
                    },
                    {
                        name: "action",
                        visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('action'),
                        render: function (data, type, row) {
                            let returnData = "";

                            returnData +=
                                '<div data-bs-toggle="tooltip" title="Click here to edit customer"><button class="btn btn-secondary image p-2 px-4 m-1" data-recordId=\'' +
                                row.recordId +
                                "'><i class='fa fa-eye' style='font-size:1rem;'></i></button></div>";
                            return returnData;
                        },
                    },

                ],
                processing: true,
                dom: "Bfrtip",
                order: [[0, "desc"]],
                lengthMenu: [
                    [10, 20, 30, 50, -1],
                    [10, 20, 30, 50, "All"],
                ],
                columnDefs: [
                    {
                        targets: 0,
                        render: function (data, type, row, meta) {
                            return type === "export" ? meta.row + 1 : data;
                        },
                    },
                ],
                createdRow: function (row, data, rowIndex) {
                    var title: any = [];
                    $("#workOrderTable thead tr th").each(function () {
                        title.push($(this).text());
                    });
                    $.each($("td", row), function (colIndex) {
                        $(this).attr("data-title", title[colIndex]);
                    });
                },

            });
            table.on('page', function () {
                const currentPage = table.page.info();

                _this.setState({
                    page: currentPage.page
                })

            });
            $(document)[0].oncontextmenu = function () { return false; }

            $('#workOrderTable tbody').mousedown(function (e) {
                e.preventDefault()
                if (e.buttons === 1) return true;
                e.preventDefault();
                var top = e.clientY;
                var left = e.clientX;
                const recordString = $(e.target).parents('tr').find('a.clickable_entry').attr('data-value');
                let record;
                if (recordString) {
                    record = JSON.parse(recordString)
                }
                if (record) {
                    $('#view_image_link').attr('data-recordId', record.recordId)
                    $("#context-menu").css({
                        top: top,
                        left: left
                    }).show();
                }
                return false;
            })
            $('html').click(function () {
                $("#context-menu").hide();
            })
            $("#context-menu a").click(function (e) {
            });
            _this.displaySelectedColumns(table);
        });


        $(".dt-buttons").remove();
    };
    async getFilterRecords() {
        const deploymentManagerRecord = await getUsers();
        const deploymentManagerResults: any = deploymentManagerRecord
            ? deploymentManagerRecord
            : [];
        deploymentManagerResults.sort(function (a: any, b: any) {
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        const locationRecord = await getLocationsForFilter();
        const locationResults: any = locationRecord.data ? locationRecord.data : [];
        locationResults.sort(function (a: any, b: any) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        const machinesRecords = await getMachinesNameAndSerials();
        const machinesResults: any = machinesRecords.data ? machinesRecords.data : [];
        machinesResults.sort(function (a: any, b: any) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        this.setState({
            managerDB: deploymentManagerResults,
            locationDB: locationResults,
            machineDB: machinesResults,
        });
    }


    handleDownloadExcel = async () => {
        this.setState({
            downloadLoading: true
        })
        const filter = this.getFilter();
        const result: any = await getWorkOrders(filter);
        const getDeploymentManagerName = (id: string) => {
            const manager = this.state.managerDB.find(
                (record: any) => {
                    return record.id === id
                }
            );
            return manager ? manager.firstName : '';
        };
        const getLocationName = (id: string) => {
            const location = this.state.locationDB.find(
                (record: any) => {
                    return record.id === id
                }
            );
            return location ? location.name : '';
        };

        const excelRecords = [];
        let counter = 0;
        for (const record of result.data) {
            const matchedMachine1 = this.state.machineDB.find((machine: any) => machine.id === record.machineId1);
            const machineName1 = matchedMachine1 ? matchedMachine1.name : '';
            const machineSerialNumber1 = matchedMachine1 ? matchedMachine1.serialNumber : '';
            const matchedMachine2 = this.state.machineDB.find((machine: any) => machine.id === record.machineId2);
            const machineName2 = matchedMachine2 ? matchedMachine2.name : '';
            const machineSerialNumber2 = matchedMachine2 ? matchedMachine2.serialNumber : '';
            counter++;
            const tempRecord = {
                "id": counter,
                "Manager Name": getDeploymentManagerName(record.deploymentManagerId),
                "Location Name": getLocationName(record.locationId),
                "Close Up Media": record.closeUpMedia.join(', '),
                "Store Set Up Media": record.storeSetUpMedia.join(', '),
                "Drain Set Up Media": record.drainSetUpMedia.join(', '),
                "Serial Number Media": record.serialNumberMedia.join(', '),
                "Comment": record.comment,
                "MachineId 1": record.machineId1 ? `${machineSerialNumber1} (${machineName1})` : '',
                "Is Second Machine Installed": record.isSecondMachineInstalled === true ? "Yes" : "No",
                "MachineId 2": record.machineId2 ? `${machineSerialNumber2} (${machineName2})` : '',
                "FirstName": record.firstName,
                "LastName": record.lastName,
                "Date": record.date ? Moment.utc(record.date)
                .local()
                .format("YYYY-MM-DD") : '',
                "Signature": record.signature,
                "Freezer Installed": record?.installationSteps?.freezerInstalled,
                "Footer Installed": record?.installationSteps?.footerInstalled,
                "Sanitizer Installed": record?.installationSteps?.sanitizerInstalled,
                "Header Installed": record?.installationSteps?.headerInstalled,
                "Booster Bar Installed": record?.installationSteps?.boosterBarInstalled,
                "Drainage Installed": record?.installationSteps?.drainageInstalled,
                "Internet Connected": record?.installationSteps?.internetConnected,
                "Flavor Tags Installed": record?.installationSteps?.flavorTagsInstalled,
                "Service Sticker Installed": record?.installationSteps?.serviceStickerInstalled,
                "Operating Pressures": record?.installationSteps?.operatingPressures,
                "One Two Three Stickers Installed": record?.installationSteps?.oneTwoThreeStickersInstalled,
                "PalmTree": record?.installationSteps?.serviceStickerInstalled,
                "Filled Freezer": record?.installationSteps?.filledFreezer,

                "Blended Four Smoodies": record?.testPerformance?.blendedFourSmoodi,
                "Sanitizer": record?.testPerformance?.sanitizer,
                "Get Status": record?.testPerformance?.getStatus,
                "Making Smoodies": record?.staffTraining.makingSmoodies,
                "Benefits Of Smoodi (Trained Staff)": record?.staffTraining?.benefitsOfSmoodi,
                "Accessing Service Menu (Trained Staff)": record?.staffTraining?.accessingServiceMenu,
                "Cleaning Wipers (Trained Staff)": record?.staffTraining?.cleaningWipers,
                "Checking Sanitizer (Trained Staff)": record?.staffTraining?.checkingSanitizer,

                "BoosterPump": record?.materialInstallation?.boosterPump,
                "Benefits Of Smoodi (Optional Materials)": record?.materialInstallation?.benefitsOfSmoodi,
                "Accessing Service Menu (Optional Materials)": record?.materialInstallation?.accessingServiceMenu,
                "Cleaning Wipers (Optional Materials)": record?.materialInstallation?.cleaningWipers,
                "Checking Sanitizer (Optional Materials)": record?.materialInstallation?.checkingSanitizer,

                "Action": record.requiredTask.map((a: any) => a.action).join(', '),
                "Priority": record.requiredTask.map((a: any) => a.priority).join(', '),
                "Due Date": record.requiredTask.map((a: any) => a.dueDate ? Moment.utc(a.dueDate)
                .local()
                .format("YYYY-MM-DD") : '',).join(', '),
                "Created At": Moment.utc(record.createdAt)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss"),
            };
            excelRecords.push(tempRecord);
        }
        DownloadExcel(excelRecords, "SHEET_NAME", "Work_order");
        this.setState({
            downloadLoading: false
        })
    };


    refreshTable = async () => {
        await this.getFilterRecords();
        this.renderDataTable([]);
    }

    // filter data submit
    searchData = async () => {
        this.setState({
            addLoading: true,
        });
        const url = this.getURL(`/workOrder?page=${this.state.page}`);
        window.history.replaceState({}, '', url);;
        this.setState({
            finalUrl: this.getURL('')
        });

        this.refreshTable()
        this.renderDataTable([]);
        this.setState({
            addLoading: false,
        });
        return false;
    };
    removeSearch = async () => {
        this.setState({
            filterLoading: true,
        });

        // eslint-disable-next-line
        this.state.resetFrom.current.state.inputValue = "";
        // eslint-disable-next-line
        this.state.resetDate.current.state.inputValue = "";
        // eslint-disable-next-line
        this.state.resetTo.current.state.inputValue = "";
        this.setState({
            filterToDate: "",
            filterFromDate: "",
            filterFirstName: "",
            filterLastName: "",
            filterDate: "",
            filterLocation: "",
            deploymentManagerSelected: null,
            locationSelected: null,
            filterDeployment: "",
        });
        window.history.replaceState({}, '', "/workOrder");
        await this.getFilterRecords();
        this.renderDataTable([]);
        this.setState({
            filterLoading: false,
        });
        return false;
    };
    handleSurveyClose = () => {
        this.setState({
            signatureImageModal: false,

            currentlyOpenRecord: {},
        });
    };
    handleWorkOrderImageClose = () => {
        this.setState({
            workOrderImageModal: false,
            currentWorkOrderId: "",
            imageContent: {},
        })
    }
    // async getColumnData() {
    getColumnData = async () => {
        var _ = this;
        const data = await getModuleDataDisplaySettingByKey(this.state.moduleKeyName);
        if (data) {
            _.setState({ selectedColumns: data.metaValue })
            return data.metaValue;
        }
        return [];
    }

    displaySelectedColumns = async (table: any) => {
        var _this = this;
        if (_this.state.selectedColumns.length > 0) {
            table.settings().init().columns?.map(function (val: any, key: any) {
                if (!_this.state.selectedColumns.includes(val.name)) {
                    table.column(key).visible(false);
                }
                return false;
            });
        }
        $('#workOrderTable').show();
        return false;
    };

    handleColumnDataSelect = async (e: any) => {
        const selCol = (e.target.checked)
            ? [...this.state.selectedColumns, e.target.value]
            : this.state.selectedColumns.filter((item: string) => item !== e.target.value);
        this.setState({ selectedColumns: selCol });
    };

    handleColumnDataSubmit = async (e: any) => {
        const selectedColumns = this.state.selectedColumns;
        await updateModuleDataDisplaySettingByKey(this.state.moduleKeyName, selectedColumns);
        this.refreshTable();
    };
    handleWorkOrderFilterSet = async () => {
        if (this.getURL('')) {
            if (this.state.filterDeployment) {
                const selectManager = this.state.managerDB.filter((data: any) => this.state.filterDeployment.includes(data.id))
                const finalSelectedManagerResponse = selectManager.map((x: any) => {
                    return {
                        value: x.id,
                        label: x.firstName
                    }
                })
                if (JSON.stringify(finalSelectedManagerResponse) !== JSON.stringify(this.state.deploymentManagerSelected)) {
                    this.setState({
                        deploymentManagerSelected: finalSelectedManagerResponse,
                    });
                }
            }
            if (this.state.filterLocation) {
                const selectLocation = this.state.locationDB.filter((data: any) => this.state.filterLocation.includes(data.id))
                const finalSelectedLocationResponse = selectLocation.map((x: any) => {
                    return {
                        value: x.id,
                        label: x.name
                    }
                })
                if (JSON.stringify(finalSelectedLocationResponse) !== JSON.stringify(this.state.locationSelected)) {
                    this.setState({
                        locationSelected: finalSelectedLocationResponse,
                    });
                }
            }
        }
    };
    render() {
        return (
            <Suspense>
                <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
                    <div
                        id="kt_app_toolbar_container"
                        className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
                        style={{ maxWidth: "100%" }}
                    >
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                                Work Order List
                            </h1>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
                            <button
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#customer-filter"
                                aria-expanded="false"
                                aria-controls="customer-filter"
                                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                                <KTSVG
                                    path="/media/icons/duotune/general/gen031.svg"
                                    className="svg-icon-4 me-1"
                                />
                                Filter
                            </button>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                <li className="breadcrumb-item text-muted">
                                    <a href="/" className="text-muted text-hover-primary fs-5">
                                        Home
                                    </a>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-400 w-5px h-2px" />
                                </li>
                                <li className="breadcrumb-item text-dark fs-5">
                                    Work Order List
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="collapse" id="customer-filter" aria-labelledby="customer-filter">
                    <div className="card mt-9">
                        <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                            <div className="row mt-6">
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                                    <label className="form-label fs-4 fw-bold">Created At (From Date)</label>
                                    <Datetime
                                        ref={this.state.resetFrom}
                                        closeOnSelect
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat="HH:mm"
                                        initialValue={this.state.filterFromDate}
                                        onChange={(e: any) => {
                                            const dt = e["_d"];
                                            this.setState({
                                                filterFromDate: dt,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                                    <label className="form-label fs-4 fw-bold">Created At (To Date)</label>
                                    <Datetime
                                        ref={this.state.resetTo}
                                        closeOnSelect
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat="HH:mm"
                                        initialValue={this.state.filterToDate}
                                        onChange={(e: any) => {
                                            const dt = e["_d"];
                                            this.setState({
                                                filterToDate: dt,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                                    <label className="form-label fs-4 fw-bold">Deployment Manager</label>
                                    <ReactSelect
                                        className="react-select"
                                        classNamePrefix="my-react-select"
                                        placeholder=""
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={(selected: any) => {
                                            const value = selected?.map((e: any) => e.value);
                                            this.setState({
                                                deploymentManagerSelected: selected,
                                                filterDeployment: value,
                                            });
                                        }}
                                        components={{
                                            Option,
                                        }}
                                        value={this.state.deploymentManagerSelected}
                                        options={Array.from(this.state.managerDB).map(
                                            (value: any) => {
                                                return {
                                                    value: value.id,
                                                    label: value.firstName,
                                                };
                                            }
                                        )}
                                    />

                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                                    <label className="form-label fs-4 fw-bold">Locations</label>
                                    <ReactSelect
                                        className="react-select"
                                        placeholder=""
                                        classNamePrefix="my-react-select"
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={(selected: any) => {
                                            const value = selected?.map((e: any) => e.value);
                                            this.setState({
                                                locationSelected: selected,
                                                filterLocation: value,
                                            });
                                        }}
                                        components={{
                                            Option,
                                        }}
                                        value={this.state.locationSelected}
                                        options={Array.from(this.state.locationDB).map(
                                            (value: any) => {
                                                return {
                                                    value: value.id,
                                                    label: value.name,
                                                };
                                            }
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                                    <label className="form-label fs-4 fw-bold">First Name</label>

                                    <input
                                        autoComplete="off"
                                        className={clsx("form-control")}

                                        name="filterFirstName"
                                        value={this.state.filterFirstName}
                                        onChange={(event: any) => {
                                            this.setState({
                                                filterFirstName: event.target.value,
                                            });
                                        }}
                                    />

                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                                    <label className="form-label fs-4 fw-bold">Last Name</label>

                                    <input
                                        autoComplete="off"
                                        className={clsx("form-control")}

                                        name="filterLastName"
                                        value={this.state.filterLastName}
                                        onChange={(event: any) => {
                                            this.setState({
                                                filterLastName: event.target.value,
                                            });
                                        }}
                                    />

                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                                    <label className="form-label fs-4 fw-bold">Date</label>
                                    <Datetime
                                        ref={this.state.resetDate}
                                        closeOnSelect
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat="HH:mm"
                                        initialValue={this.state.filterDate}
                                        onChange={(e: any) => {
                                            const dt = e["_d"];
                                            this.setState({
                                                filterDate: dt,
                                            });
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                    <button
                                        type="submit"
                                        onClick={this.searchData}
                                        className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                                    >
                                        {this.state.addLoading && (
                                            <span
                                                className="indicator-progress"
                                                style={{ display: "block" }}
                                            >
                                                Please Wait..
                                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                            </span>
                                        )}
                                        {!this.state.addLoading && (
                                            <span className="indicator-label">ADD FILTER</span>
                                        )}
                                    </button>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                                    <button
                                        type="submit"
                                        onClick={this.removeSearch}
                                        className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                                    >
                                        {this.state.filterLoading && (
                                            <span
                                                className="indicator-progress"
                                                style={{ display: "block" }}
                                            >
                                                Please Wait..
                                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                            </span>
                                        )}
                                        {!this.state.filterLoading && (
                                            <span className="indicator-label">RESET FILTER</span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mt-9">
                    <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-2 col-lg-3 col-md-4 col-12">

                                            <Columns
                                                columnData={this.state.columnData}
                                                selectedColumns={this.state.selectedColumns}
                                                handleColumnDataSelect={this.handleColumnDataSelect}
                                                handleColumnDataSubmit={this.handleColumnDataSubmit}
                                            />
                                        </div>

                                        <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                                            <button
                                                onClick={this.handleDownloadExcel}
                                                className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                                            >
                                                {this.state.downloadLoading && (
                                                    <span
                                                        className="indicator-progress"
                                                        style={{ display: "block" }}
                                                    >
                                                        Please Wait..
                                                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                                    </span>
                                                )}
                                                {!this.state.downloadLoading && (
                                                    <span className="indicator-label"> <KTSVG
                                                        path="/media/icons/duotune/files/fil021.svg"
                                                        className="svg-icon-4 me-2"
                                                    />
                                                        Export Excel</span>
                                                )}

                                            </button>
                                        </div>
                                    </div>
                                    <table
                                        id="workOrderTable"
                                        className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Sr.No
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    DeploymentManager Name
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Location Name
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Comment
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Machine 1
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Is Second Machine Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Machine 2
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    First Name
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Last Name
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Date
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Signature
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Freezer Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Footer Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Sanitizer Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Header Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Booster Bar Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Drainage Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Internet Connected
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Flavor Tags Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Service Sticker Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Operating Pressures
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    One Two Three Stickers Installed
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Palm Tree
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Filled Freezer
                                                </th>

                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Blended Four Smoodies
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Sanitizer
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Get Status
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Making Smoodies
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Benefits Of Smoodi (Trained Staff)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Accessing Service Menu (Trained Staff)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Cleaning Wipers (Trained Staff)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Checking Sanitizer (Trained Staff)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Booster Pump
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Benefits Of Smoodi (Optional Materials)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Accessing Service Menu (Optional Materials)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Cleaning Wipers (Optional Materials)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Checking Sanitizer (Optional Materials)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Action (Required Task)
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Priority
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Due Date
                                                </th>
                                                <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                                                    Action
                                                </th>

                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div
                            className="dropdown-menu d-block mb-5 "
                            aria-labelledby="dropdownMenuButton"

                        >
                            <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                id="context-menu"
                            >
                                <li>
                                    <button
                                        className="dropdown-item image" id="view_image_link"
                                    >
                                        <i className="fa fa-pencil fs-6"></i>{" "}
                                        <span className="ms-2">View image</span>
                                    </button>
                                </li>

                            </ul>

                        </div>
                        {this.state.signatureImageModal === true && (
                            <SignatureImage
                                currentlyOpenRecord={this.state.currentlyOpenRecord}
                                signatureImageModal={this.state.signatureImageModal}
                                handleSurveyClose={this.handleSurveyClose}
                                refreshTable={this.refreshTable}
                            />
                        )}
                        {this.state.workOrderImageModal === true && (
                            <ImageModal
                                currentWorkOrderId={this.state.currentWorkOrderId}
                                imageContent={this.state.imageContent}
                                workOrderImageModal={this.state.workOrderImageModal}
                                handleWorkOrderImageClose={this.handleWorkOrderImageClose}
                                refreshTable={this.refreshTable}
                            />)}</div>
                </div>
            </Suspense>
        );
    }
}

export default WorkOrderList;

import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import "./WorkOrder.css";
import mainLogo from "../../../images/smoodi_Logo.png";
import SignaturePad from "react-signature-pad-wrapper";
import Select from "react-select";
import { createWorkOrder, generateSignedUrl } from "../../models/_worOrder";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import {
  MediaFile,
  validateObject,
  validateRequiredTasks,
  workOrderInitialValues,
  workOrderValidationSchema,
} from "./WorkOrderHelper";
import Checklist from "./Tabs/Checklist";
import FileUpload from "./Tabs/FileUpload";
import UserInfo from "./Tabs/UserInfo";

const InstallationWorkOrderForm: React.FC = () => {
  const [closeImages, setCloseImages] = useState<MediaFile[]>([]);
  const [storeImages, setStoreImages] = useState<MediaFile[]>([]);
  const [drainImages, setDrainImages] = useState<MediaFile[]>([]);
  const [serialNumberImages, setSerialNumberImages] = useState<MediaFile[]>([]);
  const [closeResultImages, setCloseResultImages] = useState([]);
  const [storeResultImages, setStoreResultImages] = useState([]);
  const [drainResultImages, setDrainResultImages] = useState([]);
  const [serialNumberResultImages, setSerialNumberResultImages] = useState([]);
  const [closeUrl, setCloseUrl] = useState<string[]>([]);
  const [storeUrl, setStoreUrl] = useState<string[]>([]);
  const [drainUrl, setDrainUrl] = useState<string[]>([]);
  const [serialNumberUrl, setSerialNumberUrl] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [locationDB, setLocationDB] = useState([]);
  const [managerDB, setManagerDB] = useState([]);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [submitClick, setSubmitClick] = useState(false);
  const resetLocation = React.useRef<any>(null);
  const resetDeploymentManager = React.useRef<any>(null);
  const resetPriority = React.useRef<any>(null);
  const resetDate = React.useRef<any>(null);
  const resetDueDate = React.useRef<any>(null);
  const [signatureError, setSignatureError] = useState(false);
  const sigPad = useRef<SignaturePad | null>(null);

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  const fetchData = async () => {
    try {
      const API_URL = process.env.REACT_APP_FETCH_API_URL;
      const requestOptions: any = {
          method: 'GET',
          redirect: 'follow'
      };

      fetch(`${API_URL}/api/tickets/get-locations`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const locationRecord = JSON.parse(result);
          const locationIdResults: any = locationRecord?.data
            ? locationRecord?.data
            : [];
          locationIdResults.sort(function (a: any, b: any) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setLocationDB(locationIdResults);
        }).catch(error => console.log('error', error));

      fetch(`${API_URL}/api/tickets/get-users`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const deploymentManagerRecord = JSON.parse(result);
          const deploymentManagerResults: any = deploymentManagerRecord?.data
            ? deploymentManagerRecord?.data
            : [];
          deploymentManagerResults.sort(function (a: any, b: any) {
            if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
              return -1;
            }
            if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setManagerDB(deploymentManagerResults);
        }).catch(error => console.log('error', error));
    } catch (error) {
      console.log('Error while fetchData', error);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<{name: string; signedUrl: string; type: string}> => {
    const result = {name: "", signedUrl: "", type: ""};
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const selectedFilesArray: File[] = Array.from(selectedFiles);
      for (let i = 0; i < selectedFilesArray.length; i++) {
        const file = selectedFilesArray[i];
        const name = file.name;
        const type = file.type;
        try {
          const signedUrlResponse = await generateSignedUrl({
            file_name: name,
            file_type: type,
          });
          const signedUrl =
            signedUrlResponse?.data?.data?.generateSignedUrl?.url;

          const uploadedFile = await fetch(signedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadedFile.ok) {
            result.signedUrl = signedUrl;
            result.name = name;
            result.type = type;
          }
        } catch (err) {
          console.error("Error while handleFileUpload:", err);
          return result;
        }
      }
    }
    return result;
  };

  const handleCloseImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setCloseUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setCloseResultImages((previousImages) => previousImages.concat(result));
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setCloseImages((previousImages) => previousImages.concat(allImages));
    }
  };

  const handleStoreImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setStoreUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setStoreResultImages((previousImages) => previousImages.concat(result));
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setStoreImages((previousImages) => previousImages.concat(allImages));
    }
  };

  const handleDrainImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setDrainUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setDrainResultImages((previousImages) => previousImages.concat(result));
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setDrainImages((previousImages) => previousImages.concat(allImages));
    }
  };

  const handleSerialNumberImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, signedUrl, type} = await handleFileUpload(event);
    if (name && signedUrl && type) {
      const uploadedFileUrl = signedUrl.split("?")[0];
      setSerialNumberUrl((prevUrls) => [...prevUrls, uploadedFileUrl]);
      const result: any = [{name: name, content: signedUrl}];
      setSerialNumberResultImages((previousImages) =>
        previousImages.concat(result)
      );
      const allImages: MediaFile[] = [{name, type, content: uploadedFileUrl}];
      setSerialNumberImages((previousImages) =>
        previousImages.concat(allImages)
      );
    }
  };

  const removeCloseImage = (image: any) => {
    setCloseImages(closeImages.filter((e: any) => e.content !== image.content));
    URL.revokeObjectURL(image.content);
    setCloseResultImages(
      closeResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const removeStoreImage = (image: any) => {
    setStoreImages(storeImages.filter((e: any) => e.content !== image.content));
    URL.revokeObjectURL(image.content);
    setStoreResultImages(
      storeResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const removeDrainImage = (image: any) => {
    setDrainImages(drainImages.filter((e: any) => e.content !== image.content));
    URL.revokeObjectURL(image.content);
    setDrainResultImages(
      drainResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const removeSerialNumberImage = (image: any) => {
    setSerialNumberImages(
      serialNumberImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
    setSerialNumberResultImages(
      serialNumberResultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  };

  const handleErrorCounter = (values: any) => {
    let count = 0;
    if (!values?.deploymentManagerId) {
      count++;
    }
    if (!values?.locationId) {
      count++;
    }
    if (!values?.firstName) {
      count++;
    }
    if (!values?.lastName) {
      count++;
    }
    if (!values?.date) {
      count++;
    }
    if (sigPad?.current?.isEmpty()) {
      count++;
      setSignatureError(true);
    } else {
      setSignatureError(false);
    }
    setErrorCount(count);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="wo-body">
      <div className="wo-logo-container">
        <img src={mainLogo} alt="Smoody Logo" className="wo-logo" />
      </div>

      {errorCount > 0 && submitClick && (
        <div className="error-banner">
          <span>
            There is <div className="error-badge">{errorCount}</div> error on
            this page.
          </span>
          {/* <button>See Errors</button> */}
        </div>
      )}

      <div className="wo-form-wrapper">
        <div className="wo-header-container">
          <h2 className="wo-header-title">Installation Work Order Form</h2>
        </div>
      </div>

      <div className="wo-form-wrapper">
        <div className="wo-form-container">
          <div className="row">
            <Formik
              initialValues={workOrderInitialValues}
              validationSchema={workOrderValidationSchema}
              onSubmit={async (values, {resetForm, setSubmitting}) => {
                setLoading(true);
                if (sigPad?.current?.isEmpty()) {
                  setSignatureError(true);
                  return false;
                }

                const signatureImg: any = {};
                let signatureUrls = "";
                if (sigPad.current) {
                  const signature = "signature.png";
                  const signatureImage = new Image();
                  signatureImage.src = sigPad.current.toDataURL();

                  const imageArrays = {
                    name: signature,
                    content: signatureImage.src,
                  };
                  signatureImg["signature"] = imageArrays;
                  const signatureDataURL = sigPad.current.toDataURL();
                  try {
                    const signatureSignedUrlResponse = await generateSignedUrl({
                      file_name: signature,
                      file_type: "image/png",
                    });
                    const signatureSignedUrl =
                      signatureSignedUrlResponse?.data?.data?.generateSignedUrl
                        ?.url;

                    const signatureUploadedFile = await fetch(
                      signatureSignedUrl,
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "image/png",
                        },
                        body: await fetch(signatureDataURL).then((res) =>
                          res.blob()
                        ),
                      }
                    );

                    if (signatureUploadedFile.ok) {
                      const uploadedSignatureUrl =
                        signatureSignedUrl.split("?")[0];

                      const signatureImageArray = {
                        name: signature,
                        content: uploadedSignatureUrl,
                      };
                      signatureUrls = signatureImageArray.content;
                    }
                  } catch (err) {
                    console.error("Error while uploading signature:", err);
                  }
                }

                const $inputData: any = {
                  deploymentManagerId: values.deploymentManagerId,
                  locationId: values.locationId,
                  closeUpMedia: closeUrl,
                  storeSetUpMedia: storeUrl,
                  drainSetUpMedia: drainUrl,
                  serialNumberMedia: serialNumberUrl,
                  comment: values.comment,
                  isSecondMachineInstalled: values.isSecondMachineInstalled,
                  machineSerialNumber1: values.machineSerialNumber1,
                  machineName1: values.machineName1,
                  machineSerialNumber2: values.machineSerialNumber2,
                  machineName2: values.machineName2,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  date: values.date,
                  signature: signatureUrls,
                };
                if (validateObject(values.installationSteps)) {
                  $inputData["installationSteps"] = values.installationSteps;
                }
                if (validateObject(values.testPerformance)) {
                  $inputData["testPerformance"] = values.testPerformance;
                }
                if (validateObject(values.staffTraining)) {
                  $inputData["staffTraining"] = values.staffTraining;
                }
                if (validateObject(values.materialInstallation)) {
                  $inputData["materialInstallation"] =
                    values.materialInstallation;
                }
                const requiredTasks = validateRequiredTasks(
                  values.requiredTask
                );
                if (requiredTasks?.length) {
                  $inputData["requiredTask"] = requiredTasks;
                }

                const result = await createWorkOrder($inputData);
                if (result?.data?.data?.createWorkOrder) {
                  toast.success("Work Order created successfully");
                  resetLocation?.current?.setValue("");
                  resetDeploymentManager?.current?.setValue("");
                  resetPriority?.current?.setValue("");
                  resetDate.current.state.inputValue = "";
                  resetDueDate.current.state.inputValue = "";
                  setCloseUrl([]);
                  setCloseImages([]);
                  setStoreUrl([]);
                  setStoreImages([]);
                  setDrainUrl([]);
                  setDrainImages([]);
                  setSerialNumberUrl([]);
                  setSerialNumberImages([]);
                  clearSignature();
                  setSignatureError(false);
                  setLoading(false);
                  resetForm();
                  setSubmitClick(false);
                } else {
                  setSubmitting(false);
                  setLoading(false);
                  const messages = result?.data?.errors.map(
                    (e: any) => e.message
                  );
                  toast.error(messages.join(","));
                }
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => {
                return (
                  <div>
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <div className="col-6">
                        <div
                          className={clsx("wo-form-group", {
                            "error-box":
                              Boolean(errors.deploymentManagerId) &&
                              touched.deploymentManagerId,
                          })}
                        >
                          <label
                            htmlFor="installerName"
                            className="wo-label required"
                          >
                            Deployment Manager
                          </label>
                          <Select
                            ref={resetDeploymentManager}
                            className={clsx("react-select", {
                              "is-invalid":
                                Boolean(errors.deploymentManagerId) &&
                                touched.deploymentManagerId,
                            })}
                            classNamePrefix="my-react-select"
                            id="deploymentManagerId"
                            name="deploymentManagerId"
                            placeholder="Select Deployment Manager"
                            onChange={(e: any) => {
                              setFieldValue("deploymentManagerId", e.value);
                              values.deploymentManagerId = e.value;
                              handleErrorCounter(values);
                            }}
                            options={Array.from(managerDB).map((value: any) => {
                              return {
                                value: value.id,
                                label: value.first_name,
                              };
                            })}
                          />
                          <div
                            className={clsx("", {
                              "wo-error mt-4":
                                Boolean(errors.deploymentManagerId) &&
                                touched.deploymentManagerId,
                            })}
                          >
                            <i
                              className="fa fa-exclamation-circle ms-2 error-icon"
                              aria-hidden="true"
                            ></i>
                            <ErrorMessage name="deploymentManagerId" />
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div
                          className={clsx("wo-form-group", {
                            "error-box":
                              Boolean(errors.locationId) && touched.locationId,
                          })}
                        >
                          <label
                            htmlFor="locationId"
                            className="wo-label required"
                          >
                            Location Name
                          </label>
                          <Select
                            ref={resetLocation}
                            className={clsx("react-select", {
                              "is-invalid":
                                Boolean(errors.locationId) &&
                                touched.locationId,
                            })}
                            classNamePrefix="my-react-select"
                            id="locationId"
                            name="locationId"
                            placeholder="Select Location"
                            onChange={(e: any) => {
                              setFieldValue("locationId", e.value);
                              values.locationId = e.value;
                              handleErrorCounter(values);
                            }}
                            options={Array.from(locationDB).map(
                              (value: any) => {
                                return {
                                  value: value.id,
                                  label: value.name,
                                };
                              }
                            )}
                          />
                          <div
                            className={clsx("", {
                              "wo-error mt-4":
                                Boolean(errors.locationId) &&
                                touched.locationId,
                            })}
                          >
                            {" "}
                            <i
                              className="fa fa-exclamation-circle ms-2 error-icon"
                              aria-hidden="true"
                            ></i>{" "}
                            <ErrorMessage name="locationId" />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          borderTop: "1px solid #dbdce4",
                          marginTop: "3rem",
                          marginBottom: "3rem",
                        }}
                      ></div>

                      <Checklist
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        resetDueDate={resetDueDate}
                        resetPriority={resetPriority}
                      />

                      <FileUpload
                        handleCloseImageChange={handleCloseImageChange}
                        closeImages={closeImages}
                        removeCloseImage={removeCloseImage}
                        handleStoreImageChange={handleStoreImageChange}
                        storeImages={storeImages}
                        removeStoreImage={removeStoreImage}
                        handleDrainImageChange={handleDrainImageChange}
                        drainImages={drainImages}
                        removeDrainImage={removeDrainImage}
                        handleSerialNumberImageChange={handleSerialNumberImageChange}
                        serialNumberImages={serialNumberImages}
                        removeSerialNumberImage={removeSerialNumberImage}
                      />

                      <UserInfo
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleErrorCounter={handleErrorCounter}
                        resetDate={resetDate}
                        signatureError={signatureError}
                        submitClick={submitClick}
                        sigPad={sigPad}
                        clearSignature={clearSignature}
                      />

                      <div
                        style={{
                          borderTop: "1px solid #dbdce4",
                          marginTop: "3rem",
                          marginBottom: "3rem",
                        }}
                      ></div>
                      <div className="button-container">
                        <button
                          type="submit"
                          className="btn btn-primary me-4 fs-4"
                          onClick={(e) => {
                            setSubmitClick(true);
                            handleErrorCounter(values);
                          }}
                        >
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{display: "block"}}
                            >
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                          )}
                          {!loading && (
                            <span className="indicator-label">Submit</span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                );
              }}
            </Formik>
            <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallationWorkOrderForm;
